/* @flow */
/* Button bar at the bottom of a Photo Card */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useMutation } from '@apollo/client';
import { jsx } from 'theme-ui'; // eslint-disable-line
import t from 'counterpart';
import styled from '@emotion/styled';
import { Text, Buttonizer } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import Link from '../../../../link/';

import CantGetReleasesConfirmation from '../../../../../modals/cantGetReleasesConfirmation.jsx';
import { TEST_MANAGE_RELEASES_BUTTON } from '../../../../../../constants/pageObjectSelectors';

import { track } from '../../../../../../helpers/tools';

import { CANT_GET_RELEASE } from '../../../../../../graphql/mutations/release.js';
import { GET_USER_PHOTOS } from '../../../../../../graphql/queries/user';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
`;

const ReleaseBtnWrapper = styled.span`
  display: block;
  width: 100%;
  border-top: ${({ theme, border }) =>
    border ? `1px solid ${theme.colors.ghost60}` : ''};
  padding: 12px 0 10px 0;
  text-align: center;
`;

type Props = {
  openModal: OpenModal,
  manageReleases: Function,
  showNotification: Function,
  asset: EyeEmPhoto,
  assetLink: string,
  modelCount: number,
  authUserNickname: string,
  cantGetReleasesModalSeen: boolean,
};

const ReleaseActionBar = (props: Props) => {
  const [cantGetRelease] = useMutation(CANT_GET_RELEASE, {
    variables: { payload: { photoId: props.asset.id } },
    refetchQueries: [
      {
        query: GET_USER_PHOTOS,
        variables: { isBuyer: false, nickname: props.authUserNickname },
      },
    ],
  });

  const cantGetReleasesAction = async () => {
    await cantGetRelease();
    props.showNotification({
      notification: 'cantGetReleases',
      lifetime: 3000,
    });
  };

  const cantGetReleases = () => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: t('grid.photoCard.cantGetReleases.eventName'),
      eventLabel: t('grid.photoCard.cantGetReleases.label', {
        count: props.modelCount,
      }),
      eventPosition: 'photo_card',
      eventAction: t('tracking.eventAction.button'),
    });
    if (!props.cantGetReleasesModalSeen) {
      track({
        eventType: 'click_inbound',
        eventName: 'access_modal_cantClearReleases',
        eventAction: t('tracking.eventAction.button'),
        eventLabel: t('grid.photoCard.cantGetReleases.label', {
          count: props.modelCount,
        }),
        eventPosition: 'photo_card',
      });
      return props.openModal({
        content: CantGetReleasesConfirmation,
        contentProps: {
          confirmAction: cantGetReleasesAction,
        },
      });
    }
    return cantGetReleasesAction();
  };

  return (
    <ThemeProvider mode="light">
      <Wrapper>
        <ReleaseBtnWrapper>
          <Text color="aqua50">
            <Link
              sx={{ variant: 'links.default' }}
              align="center"
              href={props.assetLink}
              data-test-id={TEST_MANAGE_RELEASES_BUTTON}
              onClick={() => {
                track({
                  eventType: t('tracking.eventType.inbound'),
                  eventName: t('grid.photoCard.manageReleases.eventName'),
                  eventLabel: t('grid.photoCard.manageReleases.label'),
                  eventPosition: 'photo_card',
                  eventAction: t('tracking.eventAction.button'),
                });
                props.manageReleases();
              }}>
              {t('grid.photoCard.manageReleases.label')}
            </Link>
          </Text>
        </ReleaseBtnWrapper>
        <ReleaseBtnWrapper border>
          <Buttonizer onClick={cantGetReleases}>
            <Text>
              {t('grid.photoCard.cantGetReleases.label', {
                count: props.modelCount,
              })}
            </Text>
          </Buttonizer>
        </ReleaseBtnWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};

export default ReleaseActionBar;
