// @flow
import { useState } from 'react';
import t from 'counterpart';
import { Text, Flex, Box } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';

import { track } from '../../helpers/tools';
import SignupForm from '../general/signupForm/';
import LoginForm from '../general/loginForm';

import { USER_TYPE_SELFSERVE } from '../../constants/misc';

const StyledActionText = styled(Text)`
  cursor: pointer;
`;
type Props = {
  setFirstStepTitle?: Function,
  isAuthPending: boolean,
  close?: CloseModal,
  setType?: Function,
  isInModal?: boolean,
  buyer?: boolean,
  postAuthCallback: Function,
  login: Function,
  signup: Function,
};

const AuthForm = (props: Props) => {
  const [formState, setFormState] = useState('signup');

  const clearModalAndParentCallback = (userType) => {
    // eslint-disable-next-line
    props.close && props.close();
    return props.postAuthCallback && props.postAuthCallback(userType);
  };

  const loginCallback = (authUser: AuthUser) => {
    if (props.isInModal) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventAction: t('tracking.eventAction.formSubmit'),
        eventName: t('tracking.eventName.ecomModalLogin'),
        eventLabel: '',
        eventPosition: t('tracking.eventPosition.modalContent'),
      });
    } else {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventName: 'ecom_checkout_login',
        eventAction: t('tracking.eventAction.button'),
        eventLabel: t('authForms.login.submit'),
        eventPosition: 'checkout',
      });
    }
    return clearModalAndParentCallback(authUser?.userType);
  };

  const signupCallback = (authUser: AuthUser) => {
    if (props.isInModal) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventAction: t('tracking.eventAction.formSubmit'),
        eventName: t('tracking.eventName.ecomModalSignup'),
        eventLabel: '',
        eventPosition: t('tracking.eventPosition.modalContent'),
      });
    } else {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventName: 'ecom_checkout_signup',
        eventAction: t('tracking.eventAction.button'),
        eventLabel: t('loginSignup.signupSubmit'),
        eventPosition: 'checkout',
      });
    }

    return clearModalAndParentCallback(authUser?.userType);
  };

  const toggleFormType = (event) => {
    event.preventDefault();

    if (formState === 'signup') {
      if (props.setFirstStepTitle) {
        props.setFirstStepTitle(t('checkout.account.login.title'));
      }
      // eslint-disable-next-line
      props.setType && props.setType('login');
      return setFormState('login');
    }

    if (props.setFirstStepTitle) {
      props.setFirstStepTitle(t('checkout.account.signup.title'));
    }
    // eslint-disable-next-line
    props.setType && props.setType('signup');
    return setFormState('signup');
  };

  const LoginFormTypeToggle = () => (
    <Flex>
      <Text color="grey50">{t('checkout.account.dontHaveAccount')}</Text>
      <Box pl={2} onClick={toggleFormType}>
        <StyledActionText bold>
          {t('loginSignup.signupSubmit')}
        </StyledActionText>
      </Box>
    </Flex>
  );

  const SignupFormTypeToggle = () => (
    <Flex>
      <Text color="grey50">{t('authForms.signup.haveAnAccount')}</Text>
      <Box pl={2} onClick={toggleFormType}>
        <StyledActionText bold>{t('authForms.login.submit')}</StyledActionText>
      </Box>
    </Flex>
  );

  return formState === 'login' ? (
    <LoginForm
      buyer={props.buyer}
      callback={loginCallback}
      close={props.close}
      formTypeToggle={LoginFormTypeToggle}
      initialValues={{ expectedUserType: USER_TYPE_SELFSERVE }}
      isAuthPending={props.isAuthPending}
      isCheckoutAuth
      isInModal={props.isInModal}
      onSubmit={props.login}
    />
  ) : (
    <SignupForm
      buyer={props.buyer}
      callback={signupCallback}
      formTypeToggle={SignupFormTypeToggle}
      headline="authForms.buyerSignup.headline"
      isAuthPending={props.isAuthPending}
      isCheckoutAuth
      isInModal={props.isInModal}
      onSubmit={props.signup}
    />
  );
};

AuthForm.defaultProps = {
  buyer: false,
};

export default AuthForm;
