/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import classnames from 'classnames';

import T from '../general/translate.jsx';

type Props = {
  close: CloseModal,
  confirmAction: Function,
  className?: string,
};

class CantGetReleasesConfirmation extends Component<Props> {
  confirm = () => {
    this.props.confirmAction();
    this.props.close();
  };

  render() {
    const className = classnames(this.props.className);

    return (
      <div className={className}>
        <div className="modal_content">
          <T component="h2" className="modal_h2">
            modal.cantGetReleasesConfirmation.headline
          </T>
          <T component="p" className="modal_p">
            modal.cantGetReleasesConfirmation.subline
          </T>
        </div>
        <div className="modal_buttonBox text-left">
          <T
            component="button"
            eventName="releases_cantClear_cancel"
            eventType={t('tracking.eventType.inbound')}
            eventPosition="modal_cantClearReleases"
            eventAction={t('tracking.eventAction.button')}
            eventLabel={t('delete.modal.buttons.cancel')}
            onClick={this.props.close}
            className="g_btn g_btn-whiteTrans">
            delete.modal.buttons.cancel
          </T>
          <T
            component="button"
            eventName="releases_cantClear_confirm"
            eventType={t('tracking.eventType.inbound')}
            eventPosition="modal_cantClearReleases"
            eventAction={t('tracking.eventAction.button')}
            eventLabel={t('modal.cantGetReleasesConfirmation.cantGet')}
            onClick={this.confirm}
            className="g_btn g_btn-red float-right">
            modal.cantGetReleasesConfirmation.cantGet
          </T>
        </div>
      </div>
    );
  }
}

export default CantGetReleasesConfirmation;
