import { connect } from 'react-redux';

import {
  openModal,
  showNotificationAndDismiss,
} from '../../../../../../helpers/actionCreators/redux';

import {
  getAuthUserNickname,
  getSessionField,
} from '../../../../../../reducers/';

import { SESSION_FIELD_CANT_GET_RELEASES_MODAL_SEEN } from '../../../../../../constants/misc';

import ReleasesActionBar from './releasesActionBar.jsx';

export default connect(
  (state) => ({
    authUserNickname: getAuthUserNickname(state),
    cantGetReleasesModalSeen: !!getSessionField(
      state,
      SESSION_FIELD_CANT_GET_RELEASES_MODAL_SEEN
    ),
  }),
  {
    showNotification: showNotificationAndDismiss,
    openModal,
  }
)(ReleasesActionBar);
