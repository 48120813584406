import { gql } from '@apollo/client';

import { SimplePhotoFragment } from '../fragments/photo';

export const LIKE_PHOTO = gql`
  mutation likePhoto($photoId: ID!) {
    likePhoto(photoId: $photoId) {
      ...SimplePhoto
      totalLikes
      liked
    }
  }
  ${SimplePhotoFragment}
`;

export const UNLIKE_PHOTO = gql`
  mutation unLikePhoto($photoId: ID!) {
    unLikePhoto(photoId: $photoId) {
      ...SimplePhoto
      totalLikes
      liked
    }
  }
  ${SimplePhotoFragment}
`;
