/* @flow */
import styled from '@emotion/styled';
import t from 'counterpart';

import { Button } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Cart, Check } from '@eyeem-ui/icons';

import {
  enrichConversionEventOption,
  getSelectedLicenseForPhoto,
  track,
} from '../../../helpers/tools';
import withRouter from '../../../helpers/hoc/withRouter';
import { FLYOUT_TIMEOUT, USER_TYPE_SELFSERVE } from '../../../constants/misc';
import { TEST_ADDTOCART_BUTTON } from '../../../constants/pageObjectSelectors';

import IconButton from '../../general/iconButton.jsx';

const StyledButton = styled(Button)`
  padding: 8px;

  ${({ fullWidth }) =>
    !fullWidth &&
    `
    width: 32px;
    height: 32px;
    border-radius: 2px;
  `}

  border: none;
  cursor: pointer;
`;

type Props = {
  addOrUpdateCartItem: Function,
  clearFlyout: Function,
  conversionEventOption: ConversionEventOption,
  eventPosition: string,
  fullWidth: boolean,
  gridIndex: number,
  isAuthenticated: boolean,
  isPhotoInCart: boolean,
  navigate: Function,
  photo: EyeEmPhoto,
  selectedLicenseId: string,
  userLicenses: LicensingOption[],
};

/**
 * This button triggers the add to cart action
 * It can be used on the grid and on the photoView
 *
 * In order to use on PHOTOVIEW:
 * pass the 'fullWidth' prop
 * the selected license must be passed to addOrUpdateCartItem() (item.distributionLicenseId)
 *
 * For the use on the GRID pass the 'gridIndex' prop
 *
 * if not authenticated it displays the signup / login modal (on success the add to cart action gets triggered)
 */

const AddToCartButton = (props: Props) => {
  const conversionEventOption = () =>
    enrichConversionEventOption(
      props.conversionEventOption,
      props.photo,
      props.gridIndex
    );

  // On our grids we need to set a default license id for the addToCart action. This will be the lowest available license id
  const getDefaultLicenseId = () => {
    const availableLicenseIds = props.userLicenses.map((license) => license.id);
    return Math.min(...availableLicenseIds);
  };

  // TODO: when used on the sideBar in photoView get selectedLicense from license chooser
  // userType only needed for the postAuthCallback after login through the modal (we only trigger if its self-serve)
  const addToCartAction = (isModal?: boolean, userType: string) => {
    // if its the callback from loginModal and user not selfserve dont trigger addToCart
    if (isModal && userType !== USER_TYPE_SELFSERVE) {
      return false;
    }

    props.addOrUpdateCartItem({
      added: !props.isPhotoInCart,
      isModal,
      eventPosition: props.eventPosition,
      item: {
        id: props.photo.id,
        distributionLicenseId:
          Number(props.selectedLicenseId) || getDefaultLicenseId(),
        photo: props.photo,
        selectedLicense: getSelectedLicenseForPhoto(
          props.photo,
          props.userLicenses,
          Number(props.selectedLicenseId) || getDefaultLicenseId()
        ),
      },
      eventOption: conversionEventOption(),
      isAuthenticated: props.isAuthenticated,
    });

    // when adding a photo to the cart we're showing a flyout
    // make sure to close the flyout after 4 seconds
    if (!props.isPhotoInCart) {
      clearTimeout(window.flyoutTimer);
      window.flyoutTimer = setTimeout(
        () => props.clearFlyout(),
        FLYOUT_TIMEOUT
      );
    }
    return true;
  };

  const handleClick = () => {
    if (props.isPhotoInCart) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventName: 'access_cart',
        eventAction: t('tracking.eventAction.icon'),
        eventLabel: 'icon',
        eventPosition: 'assetgrid',
      });
      return props.navigate('/market/cart');
    }
    return addToCartAction();
  };

  if (props.fullWidth) {
    return (
      <IconButton
        variant="primary"
        icon={'cart'}
        onClick={handleClick}
        fullWidth
        customProps={{ 'data-test-id': TEST_ADDTOCART_BUTTON }}>
        {t('directDownload.downloadButton.addToCart.label')}
      </IconButton>
    );
  }

  // Compact
  return (
    <ThemeProvider>
      <StyledButton
        onClick={handleClick}
        customProps={{ 'data-test-id': TEST_ADDTOCART_BUTTON }}>
        {props.isPhotoInCart ? <Check /> : <Cart />}
      </StyledButton>
    </ThemeProvider>
  );
};

export default withRouter(AddToCartButton);
