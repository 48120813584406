/* @flow */
/* eslint-env browser */
import get from 'lodash/get';
import { useState } from 'react';
import classnames from 'classnames'; // eslint-disable-line
import t from 'counterpart';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';
import { media, ThemeProvider } from '@eyeem-ui/theme';
import { Tag, Box, Flex, Text } from '@eyeem-ui/atoms';
import { CheckCircle, Check, LightboxAdd } from '@eyeem-ui/icons';

import Photo from '../../../photo';
import BlacklistCheck from '../../../blacklistCheck';
import T from '../../../translate';
import PremiumLabel from '../../../premiumLabel';

import ActionBar from './actionBar';
import ReleasesActionBar from './releasesActionBar';

import { TEST_PHOTOINGRID_CONTAINER } from '../../../../../constants/pageObjectSelectors';

import {
  ACTION_BAR_REGULAR,
  ACTION_BAR_RELEASES,
  LICENSEID_HI_RES,
} from '../../../../../constants/misc';

import { trackingTypeFromAssetType } from '../../../../../helpers/assets';
import {
  enrichConversionEventOption,
  isAlreadyPurchased,
  track,
  truncateBeforeFirstWordThatExceeds,
  isPremiumPhoto,
  compareAsStrings,
} from '../../../../../helpers/tools';

const StyledTagWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 0;
  opacity: 0;

  @media ${media.largeUp} {
    transition: opacity 120ms ease-in-out;
    opacity: ${({ show }) => (show ? 1 : 0)};
  }
`;

const ALT_TEXT_MAX_LENGTH = 16;
const eventPositionAssetGrid = 'assetgrid';

type Props = {
  className: string,
  style: mixed,
  isPhotographer: boolean,
  gridIndex: number,
  eventPosition?: string,
  width: number,
  isOnBlog?: boolean,
  // callback for overlay
  openOverlay?: Function,
  // asset to display
  asset: EyeEmAsset,
  showUser: boolean,
  isInCart: boolean,
  actionBar?: string,
  conversionEventOption: ConversionEventOption,
  storeConversionEventOption: Function,
  // display timestamp in actions?
  noTimestamp?: boolean,
  assetWasDownloaded?: boolean,
  isPhotoInSelectedLightbox: boolean,
  contentPID: PIDObject,
  additionalContextPaginatableMetadata: AdditionalContextPaginatableMetadata,
  contextPaginatableMetadata: ContextPaginatableMetadataState,
  isBuyer: boolean,
  shouldShowCaptions: boolean,
  shouldLazyLoadPhoto: boolean,
};

const PhotoCard = ({
  className,
  style,
  isPhotographer,
  gridIndex,
  eventPosition,
  width,
  isOnBlog,
  openOverlay,
  asset,
  showUser,
  isInCart,
  actionBar,
  conversionEventOption,
  storeConversionEventOption,
  noTimestamp,
  assetWasDownloaded,
  isPhotoInSelectedLightbox,
  contentPID,
  additionalContextPaginatableMetadata,
  contextPaginatableMetadata,
  isBuyer,
  shouldShowCaptions,
  shouldLazyLoadPhoto,
}: Props) => {
  const [showHoverElements, setShowHoverElements] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const createConversionEventOption = () =>
    enrichConversionEventOption(conversionEventOption, asset, gridIndex);

  const triggerOpenOverlay = (payload: {}) => {
    storeConversionEventOption({
      photoId: asset.id,
      eventOption: createConversionEventOption(),
    });
    if (openOverlay) {
      openOverlay(payload);
    }
  };

  const handlePhotoClick = () => {
    // track photo clicks before opening overlay
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: t('grid.photoCard.eventName'),
      eventAction: t('tracking.eventAction.asset'),
      eventLabel: t('grid.photoCard.eventLabel'),
      eventPosition: eventPosition || eventPositionAssetGrid,
      eventPositionIndex: gridIndex,
      eventAssetType: trackingTypeFromAssetType(asset.type),
    });

    let payload = {
      initialGridIndex: gridIndex,
      shouldCommentScroll: false,
    };

    if (
      contextPaginatableMetadata ||
      (contentPID && additionalContextPaginatableMetadata)
    ) {
      payload = {
        ...payload,
        ...contextPaginatableMetadata,
        ...contentPID,
        ...additionalContextPaginatableMetadata,
      };
    }

    triggerOpenOverlay(payload);
  };

  const handleCommentClick = () => {
    // track comment button clicks before opening overlay
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: t('grid.photoCard.eventName'),
      eventAction: t('tracking.eventAction.button'),
      eventLabel: t('grid.photoCard.comment.eventLabel'),
      eventPosition: eventPosition || eventPositionAssetGrid,
      eventPositionIndex: gridIndex,
    });
    triggerOpenOverlay({ shouldCommentScroll: true });
  };

  const altText = ({ getty, eyevision, simpleDescription }: EyeEmPhoto) => {
    const caption = getty?.headline || eyevision?.headline;

    if (caption) return caption;

    if (simpleDescription) {
      return truncateBeforeFirstWordThatExceeds(
        ALT_TEXT_MAX_LENGTH,
        simpleDescription
      );
    }

    const keywords = getty?.tags || eyevision?.tags;
    if (keywords && keywords[0]) return keywords[0].trim();

    return '';
  };

  const shouldShowPhotoOverlay = () =>
    !isOnBlog &&
    isBuyer &&
    (asset.cart ||
      isInCart ||
      isPhotoInSelectedLightbox ||
      assetWasDownloaded ||
      isAlreadyPurchased(asset));

  const shouldShowReleasesActionBar = () => actionBar === ACTION_BAR_RELEASES;

  const revealHoverElements = () => {
    setShowHoverElements(true);
    return setHasInteracted(true);
  };

  const hideHoverElements = () => setShowHoverElements(false);

  if (!asset) {
    return null;
  }

  return (
    <figure
      onMouseEnter={revealHoverElements}
      onMouseLeave={hideHoverElements}
      style={style}
      className={classnames('photoCard', className, {
        'photoCard-releases': shouldShowReleasesActionBar(),
        'photoCard-captioned': shouldShowCaptions,
      })}
      id={`gridPhoto-${gridIndex || asset.id}`}
      data-test-id={TEST_PHOTOINGRID_CONTAINER}>
      {shouldShowReleasesActionBar() && (
        <div className="photoCard_releasesNote">
          <span
            className={classnames(
              'typo_pStatic typo-inlineBlock typo_pStatic-bold',
              'photoCard_releasesNote_badge'
            )}>
            {get(asset, 'market.modelAmount')}
          </span>
          <T
            count={get(asset, 'market.modelAmount') || 0}
            className="typo_pStatic typo_pStatic-bold typo-color-white typo-inlineBlock">
            grid.photoCard.releasesNeeded
          </T>
        </div>
      )}
      {shouldShowPhotoOverlay() && (
        <div
          className={classnames('photoCard_labelsWrapper', {
            'photoCard_labelsWrapper-withActionbar': showHoverElements,
            'photoCard_labelsWrapper-withOutActionbar':
              !showHoverElements && hasInteracted,
          })}>
          <ThemeProvider>
            <Flex pl={2} flexWrap="wrap">
              {isPhotoInSelectedLightbox && (
                <Box mr={2} mb={2}>
                  <Tag
                    bold
                    text={t('grid.photoCard.overlay.lightbox')}
                    icon={() => <LightboxAdd size={12} />}
                  />
                </Box>
              )}
              {asset.cart ||
                (isInCart && (
                  <Box mr={2} mb={2}>
                    <Tag
                      bold
                      text={t('grid.photoCard.overlay.inCart')}
                      icon={() => <Check size={12} />}
                    />
                  </Box>
                ))}
              {(isAlreadyPurchased(asset) || assetWasDownloaded) && (
                <Box mr={2} mb={2}>
                  <Tag
                    bold
                    text={
                      compareAsStrings(
                        asset.market?.licensing?.purchased?.id,
                        LICENSEID_HI_RES
                      )
                        ? t('grid.photoCard.overlay.hiRes')
                        : t('grid.photoCard.overlay.purchased')
                    }
                    icon={() => <CheckCircle size={12} />}
                  />
                </Box>
              )}
            </Flex>
          </ThemeProvider>
        </div>
      )}
      <BlacklistCheck photoId={asset.id} blacklisted={asset.blacklisted}>
        <Photo
          asset={asset}
          shouldPreventDownload
          url={asset.photoUrl || asset.previewUrl}
          link={asset.relativeUrl}
          click={handlePhotoClick}
          alt={altText(asset)}
          size1="w"
          size2={width}
          className="photoCard_photo"
          loading={shouldLazyLoadPhoto ? 'lazy' : 'eager'}
        />
      </BlacklistCheck>
      <div
        className="photoCard_heightPhantom"
        style={{
          paddingTop: `${(100 * asset.height) / asset.width}%`,
        }}>
        {actionBar === ACTION_BAR_REGULAR && (
          <>
            {isPremiumPhoto(asset) && !isPhotographer ? (
              <ThemeProvider>
                <StyledTagWrapper p={2} show={showHoverElements}>
                  <PremiumLabel bold />
                </StyledTagWrapper>
              </ThemeProvider>
            ) : null}
            <TransitionGroup>
              {((showHoverElements && !isPhotographer) || isPhotographer) && (
                <CSSTransition
                  classNames="actionBarTransition"
                  timeout={{ enter: 80, exit: 80 }}>
                  <ActionBar
                    eventPosition={eventPosition || eventPositionAssetGrid}
                    isOnBlogPhoto={isOnBlog}
                    noTimestamp={noTimestamp}
                    user={asset.user}
                    onCommentClick={handleCommentClick}
                    asset={asset}
                    showUser={showUser}
                    gridIndex={gridIndex}
                    positionIndex={gridIndex}
                  />
                </CSSTransition>
              )}
            </TransitionGroup>
          </>
        )}

        {actionBar === ACTION_BAR_RELEASES && (
          <TransitionGroup>
            {showHoverElements && (
              <CSSTransition
                classNames="actionBarTransition"
                timeout={{ enter: 80, exit: 80 }}>
                <ReleasesActionBar
                  asset={asset}
                  gridIndex={gridIndex}
                  assetLink={asset.relativeUrl}
                  modelCount={get(asset, 'market.modelAmount') || 0}
                  manageReleases={handlePhotoClick}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </div>

      {shouldShowCaptions &&
        (asset.getty?.headline || asset.eyevision?.headline) && (
          <figcaption itemProp="caption">
            <ThemeProvider>
              <Text
                variant="title6"
                color="grey50"
                as="p"
                className="photoCard_caption">
                {asset.getty?.headline || asset.eyevision?.headline}
              </Text>
            </ThemeProvider>
          </figcaption>
        )}
    </figure>
  );
};

PhotoCard.defaultProps = {
  actionBar: ACTION_BAR_REGULAR,
};

export default PhotoCard;
