// @flow
import t from 'counterpart';
import styled from '@emotion/styled';
import { Button, Flex } from '@eyeem-ui/atoms';
import { Download } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import ConfirmPackDownloadModal from '../../modals/confirmPackDownloadModal/';
import { isPremiumPhoto } from '../../../helpers/tools';

const StyledButton = styled(Button)`
  padding: 8px;
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  border: none;
`;

type PackDownloadButtonCompactProps = {
  asset: EyeEmAsset,
  licenses: LicensingOption[],
  openModal: Function,
};

function PackDownloadButtonCompact({
  licenses,
  asset,
  openModal,
}: PackDownloadButtonCompactProps) {
  // Get first available license from pack options
  const selectedLicense = licenses.find((license) => {
    // Is premium photo with premium pack
    if (
      isPremiumPhoto(asset) &&
      license.pricePoints?.premium?.__typename === 'Pack'
    ) {
      return true;
    }

    // Is regular photo with regular pack
    if (license.pricePoints?.regular?.__typename === 'Pack') {
      return true;
    }

    // No relevant pack found
    return false;
  });

  const handlePackDownload = () => {
    return openModal({
      content: ConfirmPackDownloadModal,
      contentProps: {
        title: 'modal.packDownloadConfirmation.title',
        asset,
        selectedLicense,
      },
    });
  };

  return (
    <ThemeProvider>
      <StyledButton
        title={t('directDownload.downloadButton.download.label')}
        onClick={handlePackDownload}>
        <Flex alignItems="center">
          <Download />
        </Flex>
      </StyledButton>
    </ThemeProvider>
  );
}

export default PackDownloadButtonCompact;
