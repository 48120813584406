/* @flow */
/* eslint-env browser */
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import t from 'counterpart';
import get from 'lodash/get';
import styled from '@emotion/styled';

import type { ApolloClient } from 'apollo-client';

import { FLYOUT_TIMEOUT } from '../../../constants/misc';

import IconButton from '../iconButton.jsx';
import { Button } from '@eyeem-ui/atoms';
import { LightboxAdd, LightboxMinus } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import { enrichConversionEventOption, track } from '../../../helpers/tools';

import SignupBuyerModal from '../../modals/signupBuyerModal';

import {
  GET_LIGHTBOX,
  GET_LIGHTBOX_NAME,
} from '../../../graphql/queries/lightbox';
import {
  ADD_ASSET_TO_LIGHTBOX,
  REMOVE_ASSET_FROM_LIGHTBOX,
  CREATE_LIGHTBOX,
} from '../../../graphql/mutations/lightbox';

import {
  TEST_PHOTOVIEW_ADDTOLIGHTBOX_BUTTON,
  TEST_PHOTOVIEW_REMOVEFROMLIGHTBOX_BUTTON,
} from '../../../constants/pageObjectSelectors';

const StyledButton = styled(Button)`
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
`;

type Props = {
  addToLightboxUIAction: Function,
  removeFromLightboxUIAction: Function,
  clearFlyout: Function,
  fullWidth: boolean,
  conversionEventOption: boolean,
  isLightboxStripOpen: boolean,
  isPhotoInContextLightbox: boolean,
  displayIconOnly: boolean,
  gridIndex: number,
  asset: EyeEmAsset,
  contextLightboxId?: EyeEmLightboxId,
  selectLightbox: Function,
  toggleLightboxStrip: Function,
  openModal: Function,
  isAuthenticated: boolean,
  shouldLoginOnAddToLightbox: boolean,
  client: ApolloClient<any>,
};

const AddToLightbox = ({
  addToLightboxUIAction,
  removeFromLightboxUIAction,
  clearFlyout,
  fullWidth,
  conversionEventOption,
  isLightboxStripOpen,
  isPhotoInContextLightbox,
  displayIconOnly,
  gridIndex,
  asset,
  contextLightboxId,
  selectLightbox,
  toggleLightboxStrip,
  openModal,
  isAuthenticated,
  shouldLoginOnAddToLightbox,
  client,
}: Props) => {
  const { data: lightboxNameData } = useQuery(GET_LIGHTBOX_NAME, {
    variables: { lightboxId: contextLightboxId },
    skip: !contextLightboxId,
  });

  const [addAssetToLightbox] = useMutation(ADD_ASSET_TO_LIGHTBOX);
  const [createLightbox] = useMutation(CREATE_LIGHTBOX, {
    onCompleted(data) {
      selectLightbox(data.createLightbox.id);
      return addToLightbox(data.createLightbox).then(() => {
        if (!isLightboxStripOpen) {
          toggleLightboxStrip({
            isOpen: false,
          });
        }
      });
    },
  });
  const [removeAssetFromLightbox] = useMutation(REMOVE_ASSET_FROM_LIGHTBOX, {
    onCompleted(data) {
      setIsPending(false);

      // fetch lightbox for lightbox photoStrip
      client.query({
        query: GET_LIGHTBOX,
        fetchPolicy: 'network-only',
        variables: {
          lightboxId: contextLightboxId,
        },
      });
      return removeFromLightboxUIAction({
        lightboxId: contextLightboxId,
        asset: data.removeAsset,
        openFlyoutOnRemove: true,
        isLightboxStripOpen: isLightboxStripOpen,
      });
    },
  });

  const [isPending, setIsPending] = useState(false);
  // prevent memory leak if component is removed before promise resolves

  useEffect(() => {
    return () => {
      setIsPending(false);
    };
  }, []);

  const createConversionEventOption = () =>
    enrichConversionEventOption(conversionEventOption, asset, gridIndex);

  const eventOption = () => {
    if (contextLightboxId) {
      if (isPhotoInContextLightbox) {
        return `lightboxId=${contextLightboxId}&photoId=${asset.id}`;
      }

      return {
        ...createConversionEventOption(),
        lightboxId: contextLightboxId,
      };
    }
    return createConversionEventOption();
  };

  const trackingHandler = (isModal?: boolean) => {
    const eventName = t(
      isPhotoInContextLightbox
        ? `lightboxes.addToLightbox.remove.regular.eventName`
        : `lightboxes.addToLightbox.add.regular.eventName`
    );
    const getEventPosition = () => {
      if (isModal) {
        return 'tracking.eventPosition.modalContent';
      }
      if (!displayIconOnly) {
        return 'lightboxes.addToLightbox.gridEventPosition';
      }
      return 'lightboxes.addToLightbox.pviewEventPosition';
    };

    return track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: !displayIconOnly
        ? t('tracking.eventAction.button')
        : t('tracking.eventAction.icon'),
      eventLabel: !displayIconOnly
        ? getButtonLabel()
        : t('lightboxes.addToLightbox.eventLabel'),
      eventName,
      eventPosition: t(getEventPosition()),
      eventPositionIndex: gridIndex,
      eventOption: eventOption(),
    });
  };

  const signUpModal = () =>
    openModal({
      contentProps: {
        postAuthCallback: () => addToLightboxHandler(true),
      },
      content: SignupBuyerModal,
    });

  const addToLightboxHandler = (isModal?: boolean) => {
    if (isPending) return;
    // make sure to close the flyout after 4 seconds
    // TODO: we shouldn't access window like this
    clearTimeout(window.flyoutTimer);
    window.flyoutTimer = setTimeout(() => clearFlyout(), FLYOUT_TIMEOUT);

    trackingHandler(isModal);

    if (isPhotoInContextLightbox) {
      setIsPending(true);

      return removeAssetFromLightbox({
        variables: {
          lightboxId: contextLightboxId,
          assetType: asset?.type,
          assetId: asset?.id,
        },
      });
    }
    // if there is no lightbox in context we assume there is no lightbox at all
    // let's create one, make sure to open the strip and add the photo
    if (!contextLightboxId) {
      const name = t('lightboxes.addToLightbox.defaultName');
      return createLightbox({
        variables: {
          name,
        },
      });
    }

    return addToLightbox();
  };

  const addToLightbox = (newLightbox?: EyeEmLightbox) => {
    if (isPending) return;

    addToLightboxUIAction({
      lightboxId: contextLightboxId || newLightbox.id,
      asset: asset,
      isLightboxStripOpen: isLightboxStripOpen,
    });

    setIsPending(true);

    const optimisticResponse = isLightboxStripOpen && {
      __typename: 'Mutation',
      addPhoto: {
        __typename: 'Photo',
        id: asset.id,
        type: asset.type,
        photoUrl: asset.previewUrl,
        lightboxIds: [],
      },
    };

    const lightboxId = contextLightboxId || newLightbox?.id;

    return addAssetToLightbox({
      variables: {
        lightboxId,
        assetType: asset?.type,
        assetId: asset?.id,
      },
      optimisticResponse,
    })
      .then(async () => {
        await client.query({
          query: GET_LIGHTBOX,
          fetchPolicy: 'network-only',
          variables: {
            lightboxId,
          },
        });
      })
      .catch(() => {
        // Throw error maybe?
        addToLightboxUIAction({
          lightboxId: contextLightboxId || newLightbox.id,
          asset: asset,
          isLightboxStripOpen: false,
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const getButtonLabel = () =>
    t(
      isPhotoInContextLightbox
        ? 'lightboxes.addToLightbox.remove.label'
        : 'lightboxes.addToLightbox.add.label',
      {
        lightboxName:
          get(lightboxNameData, 'lightbox.name') ||
          t('lightboxes.addToLightbox.defaultName'),
      }
    );

  const getIcon = () =>
    isPhotoInContextLightbox ? 'isInLightbox' : 'addToLightbox';

  const label = getButtonLabel();
  const testID = isPhotoInContextLightbox
    ? TEST_PHOTOVIEW_REMOVEFROMLIGHTBOX_BUTTON
    : TEST_PHOTOVIEW_ADDTOLIGHTBOX_BUTTON;
  const onClick =
    !isAuthenticated && shouldLoginOnAddToLightbox
      ? signUpModal
      : addToLightboxHandler;

  if (displayIconOnly) {
    return (
      <ThemeProvider>
        <StyledButton
          variant="secondary"
          disabled={isPending}
          customProps={{ 'data-test-id': testID }}
          onClick={onClick}>
          {isPhotoInContextLightbox ? <LightboxMinus /> : <LightboxAdd />}
        </StyledButton>
      </ThemeProvider>
    );
  }

  return (
    <IconButton
      icon={getIcon()}
      variant="secondary"
      disabled={isPending}
      fullWidth={fullWidth}
      customProps={{ 'data-test-id': testID }}
      onClick={onClick}>
      {label}
    </IconButton>
  );
};
export default AddToLightbox;
