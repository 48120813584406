/* @flow */
import { useEffect } from 'react';
import t from 'counterpart';
import loadable from '@loadable/component';

import { Modal } from '@eyeem-ui/organisms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Box, Flex, Text, Button } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';

import {
  enrichConversionEventOption,
  isPremiumPhoto,
  track,
} from '../../../helpers/tools';
import useEnterpriseDownload from '../../../helpers/hooks/useEnterpriseDownload';
import { trackingTypeFromAssetType } from '../../../helpers/assets';
import {
  PRICEPOINT_PREMIUM,
  PRICEPOINT_REGULAR,
  ZAPIER_RECIPIENTS_MARKET,
} from '../../../constants/misc';
import ContactModal from '../../../views/modals/contact/';

const ClearDownloadRequirementsModal = loadable(() =>
  import('../clearDownloadRequirements/')
);

const StyledImg = styled.img`
  max-height: 200px;
`;

const StyledImageWrapper = styled.div`
  background-color: ${({ theme }) => `${theme.colors.grey0}`};
`;

const FirstPackItemWrapper = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey40}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey40}`};
`;

const PackItemWrapper = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey40}`};
`;

type Props = {
  closeModal: Function,
  conversionEventOption: ConversionEventOption,
  downloadRequirements: DownloadRequirement[],
  eventLabel?: string,
  gridIndex?: number,
  isEnterprise: boolean,
  licenseId: number,
  onDownload: Function,
  openModal: OpenModal,
  asset: EyeEmAsset,
  selectedLicense: AvailableLicense | LicensingOption,
};

const ConfirmPackDownloadModal = ({
  closeModal,
  conversionEventOption,
  downloadRequirements,
  eventLabel,
  gridIndex,
  isEnterprise,
  openModal,
  asset,
  selectedLicense,
}: Props) => {
  const { startDownload, isPending } = useEnterpriseDownload();

  useEffect(() => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: 'show_modal',
      eventName: 'ecom_confirm_download_modal_show',
      eventLabel,
      eventPosition: 'pv_stage',
      eventAssetType: trackingTypeFromAssetType(asset.type),
      eventOption: enrichConversionEventOption(conversionEventOption, asset),
    });
  }, []);

  const doDownload = () => {
    const trackingEvent = {
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: isEnterprise ? 'ecom_download' : 'ecom_downloadPackPhoto',
      eventLabel,
      eventOption: enrichConversionEventOption(conversionEventOption, asset),
      eventPosition: 'confirm_download_modal',
      eventPositionIndex: gridIndex,
      eventAssetType: trackingTypeFromAssetType(asset.type),
    };

    // if there are download requirements, go to that modal...
    if (downloadRequirements && downloadRequirements.length) {
      return openModal({
        content: ClearDownloadRequirementsModal,
        contentProps: {
          title: 'modal.packDownloadConfirmation.title',
          asset,
          selectedLicense,
          eventName: trackingEvent.eventName,
          eventLabel: trackingEvent.eventLabel,
          downloadRequirements,
        },
      });
    }

    return startDownload({
      asset,
      selectedLicense,
      trackingEvent,
    })
      .then(closeModal)
      .catch((err) => {
        err.graphQLErrors &&
          err.graphQLErrors?.forEach((error) => {
            openModal({
              content: ContactModal,
              contentProps: {
                recipient: ZAPIER_RECIPIENTS_MARKET,
                formVersion: 'support',
                title: 'directDownload.error.title',
                serverMessage: error.message,
                statusCode: error.extensions.code,
              },
            });
          });
      });
  };

  const isPremium = isPremiumPhoto(asset);
  const collection = isPremium ? PRICEPOINT_PREMIUM : PRICEPOINT_REGULAR;

  const downloadsRemaining =
    selectedLicense.payment?.remaining ||
    selectedLicense.pricePoints[collection].remaining;
  const totalDownloads =
    selectedLicense.payment?.available ||
    selectedLicense.pricePoints[collection].available;

  const getDownloadSummary = () => {
    if (downloadsRemaining === -1) {
      return t('licenses.unlimitedRemaining');
    }
    return t('licenses.remaining', {
      remaining: downloadsRemaining,
      total: totalDownloads,
    });
  };

  const packName = isPremium
    ? t('licenses.packs.premium')
    : t('licenses.packs.essential');

  return (
    <ThemeProvider>
      <Modal
        isShown
        onDismiss={closeModal}
        title={t('modal.confirmPackDownload.title')}>
        <Flex flexDirection="column" p={24}>
          <StyledImageWrapper>
            <Flex justifyContent="center">
              <StyledImg src={asset.previewUrl} alt="photo" />
            </Flex>
          </StyledImageWrapper>
          <Box pt={4}>
            <Box mb={1}>
              <Text color="grey50" variant="title6" bold>
                {t('modal.confirmPackDownload.detailsHeader')}
              </Text>
            </Box>
            <FirstPackItemWrapper justifyContent="space-between" py={2}>
              <Text color="grey50">
                {t('modal.confirmPackDownload.packTitle')}
              </Text>
              <Text>{packName}</Text>
            </FirstPackItemWrapper>
            <PackItemWrapper justifyContent="space-between" py={2}>
              <Text color="grey50">{t('modal.confirmPackDownload.type')}</Text>
              <Text>{t(`licenses.types.${selectedLicense.id}.title`)}</Text>
            </PackItemWrapper>
            <PackItemWrapper justifyContent="space-between" py={2}>
              <Text color="grey50">
                {t('modal.confirmPackDownload.downlaodsLeft')}
              </Text>
              <Text>{getDownloadSummary()}</Text>
            </PackItemWrapper>
            <Flex justifyContent="flex-end" pt={4}>
              <Box mr={2}>
                <Button
                  onClick={closeModal}
                  variant="transparent"
                  text={t('modal.confirmPackDownload.back')}
                />
              </Box>
              <Button
                disabled={isPending}
                loading={isPending}
                onClick={doDownload}
                text={t('modal.confirmPackDownload.confirm')}
              />
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </ThemeProvider>
  );
};

ConfirmPackDownloadModal.defaultProps = {
  eventLabel: 'paid_download',
  gridIndex: undefined,
};

export default ConfirmPackDownloadModal;
