/* @flow */

import { useMutation } from '@apollo/client';
import t from 'counterpart';

import { Button, IconButton } from '@eyeem-ui/atoms';
import { Plus, Check, UserAdd } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import { isAuthUserFollowingUser } from '../../../helpers/tools';
import SignupModal from '../../modals/signupModal.jsx';
import track from '../../../helpers/dataLayerEvents';

import {
  TEST_FOLLOW_BUTTON_FOLLOWED,
  TEST_FOLLOW_BUTTON,
} from '../../../constants/pageObjectSelectors';

import { FOLLOW_USER_TOGGLE } from '../../../graphql/mutations/user';

import { TOGGLE_USER_FOLLOW } from '../../../constants/actionTypes';

type Props = {
  noText?: boolean,
  eventPosition?: string,
  fullWidth?: boolean,
  className?: string,
  minimalBtn?: boolean,

  // container props
  authUserNickname?: string,
  isAuthenticated: boolean,
  user: EyeEmUser,
  data: { user: EyeEmUser },
  openModal: OpenModal,
  setAuthRef?: Function,
};

const UserFollowBtn = ({
  noText,
  eventPosition,
  fullWidth,
  className,
  minimalBtn,
  authUserNickname,
  isAuthenticated,
  user,
  openModal,
  setAuthRef,
}: Props) => {
  const [followUserToggleMutation, { loading }] =
    useMutation(FOLLOW_USER_TOGGLE);

  const onClick = () =>
    !isAuthenticated
      ? setAuthRef({
          authRef: window.location.pathname,
        }) && signUpModal()
      : toggleFollow();

  const signUpModal = () =>
    openModal({
      contentProps: {
        signupHeadline: 'modal.signUp.follow.headline',
        eventPosition: eventPosition,
        callback: () => toggleFollow(),
      },
      content: SignupModal,
    });

  const toggleFollow = () => {
    const shouldFollow = !isAuthUserFollowingUser(user);

    followUserToggleMutation({
      variables: {
        nickname: user.nickname,
        shouldFollow,
      },
    })
      .then(() => {
        track(TOGGLE_USER_FOLLOW, {
          eventPosition: eventPosition,
          shouldFollow,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const following = isAuthUserFollowingUser(user);

  if (authUserNickname !== user.nickname) {
    if (minimalBtn) {
      return (
        <Button
          variant={following ? 'primary' : 'subtle'}
          icon={UserAdd}
          onClick={onClick}
          dataTestId={
            following ? TEST_FOLLOW_BUTTON_FOLLOWED : TEST_FOLLOW_BUTTON
          }
        />
      );
    }

    const Component = noText ? IconButton : Button;
    return (
      <ThemeProvider>
        <Component
          disabled={loading}
          variant={following ? 'primary' : 'secondary'}
          icon={(following && Check) || Plus}
          fullWidth={fullWidth}
          className={className}
          onClick={onClick}
          title={
            following
              ? `${t('user.followButton.following')} ${user.nickname}`
              : `${t('user.followButton.follow')} ${user.nickname}`
          }>
          {noText
            ? null
            : following
            ? t('user.followButton.following')
            : t('user.followButton.follow')}
        </Component>
      </ThemeProvider>
    );
  }
  return null;
};

export default UserFollowBtn;
