/* @flow */
import t from 'counterpart';

import { Tag } from '@eyeem-ui/atoms';
import { TEST_PREMIUM_LABEL } from '../../constants/pageObjectSelectors';

type Props = {
  bold?: boolean,
};

function PremiumLabel(props: Props) {
  /* NOTE: Do not add the eyeem-ui ThemeProvider to this component.
           Waiting on fix to be merged.
  */
  return (
    <span data-test-id={TEST_PREMIUM_LABEL}>
      <Tag text={t('premium.label')} bg="grey0" bold={props.bold} />
    </span>
  );
}

PremiumLabel.defaultProps = {
  bold: false,
};

export default PremiumLabel;
