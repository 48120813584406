import gql from 'graphql-tag';

export const UPDATE_ENTERPRISE_ACCOUNT = gql`
  mutation updateEnterpriseAccount(
    $password: String
    $name: String!
    $email: String
  ) {
    updateEnterpriseAccount(password: $password, name: $name, email: $email) {
      name
      email
      id
    }
  }
`;

export const LICENSE_ASSET_THEN_REQUEST_DIRECT_DOWNLOAD = gql`
  mutation licenseAssetThenRequestDirectDownload(
    $assetType: AssetType!
    $assetId: String!
    $isRedownload: Boolean!
    $requestParams: RequestDirectDownloadInput!
  ) {
    licenseAssetThenRequestDirectDownload(
      requestParams: $requestParams
      assetType: $assetType
      assetId: $assetId
      isRedownload: $isRedownload
    ) {
      url
    }
  }
`;
