/* eslint-disable react/require-default-props */
/* @flow */
import get from 'lodash/get';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import Link from '../../../../link/';
import LikeBtn from '../../../../likeBtn/';
import UserFollowBtn from '../../../../userFollowBtn/';
import CommentBtn from '../../../../commentBtn/';
import AddToLightbox from '../../../../addToLightbox/';
import AgoWrap from '../../../../agoWrap.jsx';
import Photo from '../../../../photo/';
import AddToCartButton from '../../../../../general/addToCartButton';
import RedownloadButton from '../../../../../general/redownloadButton';
import PackDownloadButtonCompact from '../../../../../general/packDownloadButtonCompact';
import { contractDisallowsPartnerSources } from '../../../../../../helpers/accounts';
import { isIllustration } from '../../../../../../helpers/assets';
import {
  isPartnerSourcedAsset,
  isPremiumPhoto,
} from '../../../../../../helpers/tools';
import withRouter from '../../../../../../helpers/hoc/withRouter';
import { ASSET_TYPE_PHOTO } from '../../../../../../constants/assets';
import {
  PREVIOUS_DOWNLOADS_PHOTOS_PATH,
  PREVIOUS_DOWNLOADS_ILLUSTRATIONS_PATH,
} from '../../../../../../constants/misc';
import { GET_LICENSES } from '../../../../../../graphql/queries/license';

const ACTION_BAR_HEIGHT = 48;
const USER_PHOTO_SIZE = 32;

const Wrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: ${({ isPhotographer }) =>
    isPhotographer ? 'relative' : 'absolute'};
  z-index: 3;
  height: ${ACTION_BAR_HEIGHT}px;
  max-height: ${ACTION_BAR_HEIGHT}px;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0f0f11;
`;

const Left = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 16px;
  padding-left: 12px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 16px;
  padding-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
  justify-content: center;
`;

const Right = styled(Flex)`
  font-size: 12px;
  line-height: 16px;
  padding-right: 12px;
  height: 100%;
  float: right;
`;

const UserPhoto = styled(Photo)`
  height: ${USER_PHOTO_SIZE}px;
  min-width: ${USER_PHOTO_SIZE}px;
  float: left;
  border-radius: 16px;
  display: block;
  margin-right: 8px;
`;

const UserName = styled(Link)`
  line-height: 16px;
  overflow: hidden;
  max-width: calc(100% - ${USER_PHOTO_SIZE}px - 8px);
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: ${({ theme }) => theme.colors.grey100};
  margin-top: ${({ noTimestamp }) => (noTimestamp ? '8px' : 0)};
`;

const StyledAgoWrap = styled(AgoWrap)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - ${USER_PHOTO_SIZE}px - 8px);
  display: block;
  color: ${({ theme }) => theme.colors.ghost70};
  margin-top: ${({ noUser }) => (noUser ? '8px' : 0)};
`;

type Props = {
  onCommentClick?: Function,
  showUser?: boolean,
  asset: EyeEmAsset,
  gridIndex?: number,
  noTimestamp?: boolean,
  isBuyer?: boolean,
  isEnterpriseCustomer?: boolean,
  photographer: EyeEmUserStub,
  isOnBlogPhoto?: boolean,
  eventPosition: string,
  authUser: AuthUser,
  isBCG: boolean,
} & WithRouterProps;

const ActionBar = ({
  onCommentClick = false,
  showUser = false,
  gridIndex = false,
  noTimestamp = false,
  isBuyer = false,
  isEnterpriseCustomer = false,
  isOnBlogPhoto = false,
  asset,
  photographer,
  eventPosition,
  authUser,
  isBCG,
  query,
  getPath,
}: Props) => {
  // TODO: idealy replace this with GET_AVAILABLE_LICENSES_FOR_PHOTO query with debounce
  const { data } = useQuery(GET_LICENSES, {
    skip: !isBuyer,
    fetchPolicy: 'cache-first',
  });

  const hasPackLicense = () => {
    const isPremium = isPremiumPhoto(asset);

    return get(data, 'licenses', []).some((item) => {
      if (item.pricePoints.regular?.__typename === 'Pack' && !isPremium)
        return true;
      if (item.pricePoints.premium?.__typename === 'Pack') return true;
      return false;
    });
  };

  const shouldRenderAddToCartButton = () => {
    return (
      isBuyer &&
      !isEnterpriseCustomer &&
      asset.isForSale &&
      !asset.market.licensing?.purchased &&
      !hasPackLicense()
    );
  };

  const shouldRenderRedownloadButton = () => {
    const isSelfServeAndPhotoIsPurchased =
      isBuyer && !isEnterpriseCustomer && asset?.market?.licensing?.purchased;
    const isEnterpriseOnPurchaseHistory =
      isEnterpriseCustomer &&
      (getPath() === PREVIOUS_DOWNLOADS_PHOTOS_PATH ||
        getPath() === PREVIOUS_DOWNLOADS_ILLUSTRATIONS_PATH);
    const isBcgUsingBcgCollectionFilter =
      isBCG && query.proDownloads === 'true';
    return (
      (isSelfServeAndPhotoIsPurchased ||
        isEnterpriseOnPurchaseHistory ||
        isBcgUsingBcgCollectionFilter) &&
      !(
        isPartnerSourcedAsset(asset) &&
        contractDisallowsPartnerSources(authUser)
      )
    );
  };

  const getFollowBtn = () =>
    photographer && (
      <UserFollowBtn
        noText
        minimalBtn
        user={photographer}
        eventPositionIndex={gridIndex}
        eventPosition={eventPosition}
      />
    );

  const getLikeBtn = () => (
    <LikeBtn
      photo={asset}
      photoId={asset.id}
      eventPositionIndex={gridIndex}
      eventPosition={eventPosition}
    />
  );

  const getCommentBtn = () => (
    <CommentBtn grid photo={asset} onClick={onCommentClick} />
  );

  const getAddToLightboxBtn = () => {
    return (
      <AddToLightbox displayIconOnly asset={asset} gridIndex={gridIndex} />
    );
  };

  const getAddToCartBtn = () => {
    return (
      shouldRenderAddToCartButton() && (
        <Box ml={2}>
          <AddToCartButton
            photo={asset}
            eventPosition={eventPosition}
            gridIndex={gridIndex}
            userLicenses={get(data, 'licenses', [])}
          />
        </Box>
      )
    );
  };

  const getRedownloadButton = () => {
    return (
      shouldRenderRedownloadButton() && (
        <Box ml={2}>
          <RedownloadButton
            asset={asset}
            eventPosition={eventPosition}
            gridIndex={gridIndex}
            purchasedLicenseId={
              asset.type === ASSET_TYPE_PHOTO
                ? get(asset, 'market.licensing.purchased.id')
                : get(asset, 'purchasedLicense.id')
            }
          />
        </Box>
      )
    );
  };

  const getUserPhoto = () =>
    photographer && (
      <UserPhoto
        link={`/u/${photographer.nickname.toLowerCase()}`}
        url={photographer.thumbUrl}
        size1="sq"
        size2={USER_PHOTO_SIZE}
      />
    );

  const getUserName = (params?: { noTimestamp?: boolean }) =>
    photographer && (
      <UserName
        href={`/u/${photographer.nickname.toLowerCase()}`}
        title={photographer.fullname || `@${photographer.nickname}`}
        noTimestamp={noTimestamp || (params && params.noTimestamp)}>
        <Text variant="title6">
          {photographer.fullname || `@${photographer.nickname}`}
        </Text>
      </UserName>
    );

  const getTimeStamp = () => (
    <StyledAgoWrap
      noUser={!showUser}
      date={asset.createdAt || asset.updatedAt}
    />
  );

  const getPackDownloadButton = () => {
    if (
      !isEnterpriseCustomer &&
      asset.isForSale &&
      !asset.market.licensing?.purchased &&
      hasPackLicense()
    ) {
      return (
        <PackDownloadButtonCompact asset={asset} licenses={data.licenses} />
      );
    }
  };

  if (
    isIllustration(asset) &&
    getPath() !== PREVIOUS_DOWNLOADS_ILLUSTRATIONS_PATH
  ) {
    return (
      <ThemeProvider>
        <Wrapper isPhotographer={!isBuyer}>
          {isBuyer ? (
            <Center>
              {getAddToLightboxBtn()}
              {getRedownloadButton()}
            </Center>
          ) : (
            [
              <Right alignItems="center" key="right">
                {getFollowBtn()}
              </Right>,
              <Left key="left">
                {showUser && getUserPhoto()}
                {showUser && getUserName()}
                {!noTimestamp && getTimeStamp()}
              </Left>,
            ]
          )}
        </Wrapper>
      </ThemeProvider>
    );
  }

  if (isOnBlogPhoto) {
    return (
      <ThemeProvider>
        <Wrapper isPhotographer={!isBuyer}>
          {isBuyer ? (
            <Right alignItems="center">
              {getAddToLightboxBtn()}
              {getAddToCartBtn()}
            </Right>
          ) : (
            <Right alignItems="center">
              {getFollowBtn()}
              {getLikeBtn()}
              {getCommentBtn()}
            </Right>
          )}
          <Left>
            {getUserPhoto()}
            {getUserName({ noTimestamp: true })}
          </Left>
        </Wrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <Wrapper isPhotographer={!isBuyer}>
        {isBuyer ? (
          <Center>
            {getAddToLightboxBtn()}
            {getAddToCartBtn()}
            {getRedownloadButton()}
            {getPackDownloadButton()}
          </Center>
        ) : (
          [
            <Right alignItems="center" key="right">
              {getLikeBtn()}
              {getCommentBtn()}
            </Right>,
            <Left key="left">
              {showUser && getUserPhoto()}
              {showUser && getUserName()}
              {!noTimestamp && getTimeStamp()}
            </Left>,
          ]
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default withRouter(ActionBar);
