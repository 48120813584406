/* @flow */
import type { Node } from 'react';

import { PureComponent } from 'react';
import t from 'counterpart';

import T from '../translate.jsx';
import { TEST_BLACKLIST_IMAGE } from '../../../constants/pageObjectSelectors';

type Props = {
  children: Node,
  photoId: EyeEmAssetId,
  isNsfwPhotoOnWhitelist?: boolean,
  allowNsfwPhoto: Function,
  blacklisted?: boolean,
  deviceType?: string,
  showNSFWContent?: boolean,
};

class BlacklistCheck extends PureComponent<Props> {
  handleClick = () =>
    this.props.allowNsfwPhoto({ photoId: this.props.photoId });

  render() {
    let allowNsfw = true;

    if (this.props.blacklisted) {
      allowNsfw = this.props.isInBoobsMode || this.props.isNsfwPhotoOnWhitelist;
    }

    if (allowNsfw) {
      return this.props.children;
    }

    return (
      <div className="blacklistCheck">
        {!allowNsfw && (
          <span
            data-test-id={TEST_BLACKLIST_IMAGE}
            key="blacklisted"
            className="blacklistCheck_outer"
            onClick={this.handleClick}>
            <div className="blacklistCheck_inner">
              <img
                alt={t('nsfw.warning.imgAlt')}
                className="blacklistCheck_mask"
                src="/node-static/img/nsfw.svg"
              />
              <T component="p" className="blacklistCheck_message">
                nsfw.warning.message
              </T>
              {this.props.deviceType && (
                <T component="p" className="blacklistCheck_subtitle">
                  nsfw.warning.subtitle.{this.props.deviceType}
                </T>
              )}
            </div>
          </span>
        )}
      </div>
    );
  }
}

export default BlacklistCheck;
