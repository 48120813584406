// @flow
import { gql } from '@apollo/client';
import { SimpleUserFragment } from '../fragments/user';
import { SimplePhotoFragment } from '../fragments/photo';

export const GET_USER = gql`
  query getUser($nickname: String!) {
    user(nickname: $nickname) {
      ...SimpleUser
      following
      follower
      totalFollowers
      totalFriends
      totalLikedPhotos
      totalPhotos
      description
      homepageUrl
      marketTotals {
        editorial
        commercial
        onHold
        partner
      }
    }
  }
  ${SimpleUserFragment}
`;

export const GET_USER_FOLLOWING = gql`
  query getUserFollowing($id: ID!, $nickname: String!) {
    user(id: $id, nickname: $nickname) {
      ...SimpleUser
      following
    }
  }
  ${SimpleUserFragment}
`;

export const GET_USER_FOLLOWERS = gql`
  query getUserFollowers(
    $id: ID!
    $nickname: String!
    $offset: Int
    $paginatableName: String
  ) {
    user(
      id: $id
      nickname: $nickname
      offset: $offset
      paginatableName: $paginatableName
    ) {
      ...SimpleUser
      followers {
        total
        items {
          ...SimpleUser
          following
          follower
          totalFollowers
          totalFriends
          totalPhotos
        }
      }
    }
  }
  ${SimpleUserFragment}
`;

export const GET_USER_FRIENDS = gql`
  query getUserFriends(
    $id: ID!
    $nickname: String!
    $offset: Int
    $paginatableName: String
  ) {
    user(
      id: $id
      nickname: $nickname
      offset: $offset
      paginatableName: $paginatableName
    ) {
      ...SimpleUser
      friends {
        total
        items {
          ...SimpleUser
          totalFollowers
          totalFriends
          totalPhotos
        }
      }
    }
  }
  ${SimpleUserFragment}
`;

export const GET_FRIENDS_PHOTOS = gql`
  query getFriendsPhotos(
    $id: ID!
    $nickname: String!
    $offset: Int
    $paginatableName: String
  ) {
    user(
      id: $id
      nickname: $nickname
      offset: $offset
      paginatableName: $paginatableName
    ) {
      ...SimpleUser
      friendsPhotos {
        total
        offset
        items {
          ...SimplePhoto
          blacklisted
          updated
          updatedAt
          totalLikes
          totalComments
          liked
          user {
            ...SimpleUser
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

export const GET_USER_PHOTOS = gql`
  query getUserPhotos(
    $nickname: String!
    $offset: Int
    $limit: Int
    $paginatableName: String
    $isBuyer: Boolean!
  ) {
    user(
      nickname: $nickname
      offset: $offset
      limit: $limit
      paginatableName: $paginatableName
    ) {
      ...SimpleUser
      photos(isBuyer: $isBuyer) {
        total
        offset
        limit
        items {
          ...SimplePhoto
          blacklisted
          eyevision {
            headline
            tags
          }
          lightboxIds @include(if: $isBuyer)
          updated
          updatedAt
          totalLikes
          totalComments
          liked
          isForSale
          user {
            id
            nickname
            fullname
            thumbUrl
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

export const USER_ME_NEWS_SETTINGS_QUERY = gql`
  query getNewsSettings {
    userMe {
      nickname
      newsSettings {
        email_confirmed
        email_community_updates
        email_photo_like
        email_photo_comment
        email_user_follower
        email_user_joined
        email_photo_comment_mention
        email_market_model_release_completed
        email_market_property_release_completed
        account_type
        account_market_seller
      }
    }
  }
`;

export const USER_ME_CREATOR_SETTINGS_QUERY = gql`
  query getCreatorSettings {
    userMe {
      email
      thumbUrl
      fullname
      nickname
      description
      homepageUrl
      newsSettings {
        email_confirmed
      }
      coverPhoto {
        previewUrl: photoUrl
      }
    }
  }
`;
