/* @flow */

import t from 'counterpart';
import styled from '@emotion/styled';
import { Button, Spinner, Text } from '@eyeem-ui/atoms';
import { Redownload } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import { enrichConversionEventOption } from '../../../helpers/tools';
import { trackingTypeFromAssetType } from '../../../helpers/assets';
import withRouter from '../../../helpers/hoc/withRouter';
import useEnterpriseDownload from '../../../helpers/hooks/useEnterpriseDownload';
import { TEST_DOWNLOAD_BUTTON } from '../../../constants/pageObjectSelectors';
import { ZAPIER_RECIPIENTS_MARKET } from '../../../constants/misc';

import ContactModal from '../../../views/modals/contact/';

const StyledButton = styled(Button)`
  border: none;
  cursor: pointer;
  padding: 8px;

  ${({ fullWidth }) =>
    !fullWidth &&
    `
    width: 32px;
    height: 32px;
    border-radius: 2px;
  `}
`;

const StyledSpinner = styled(Spinner)`
  height: 100%;
`;

type Props = {
  conversionEventOption: ConversionEventOption,
  eventPosition: string,
  fullWidth: boolean,
  gridIndex: number,
  asset: EyeEmAsset,
  purchasedLicenseId: number,
  showNotification: Function,
  openModal: OpenModal,
};

/**
 *
 * This button has one purpose only - redownload ;)
 * It can be used on the grid and on the single photo view (pass props 'fullWidth')
 */

const RedownloadButton = ({
  conversionEventOption,
  eventPosition,
  fullWidth,
  gridIndex,
  asset,
  purchasedLicenseId,
  showNotification,
  openModal,
}: Props) => {
  const { startDownload, isPending } = useEnterpriseDownload();

  const actuallyDownload = () => {
    const trackingEvent = {
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: 'ecom_download_redownload',
      eventOption: enrichedConversionEventOption(),
      eventPosition: eventPosition || 'pv_stage',
      eventPositionIndex: gridIndex,
      eventAssetType: trackingTypeFromAssetType(asset.type),
    };

    return startDownload({
      asset,
      selectedLicense: { id: purchasedLicenseId },
      trackingEvent,
      isRedownload: true,
    }).catch((err) => {
      err.graphQLErrors &&
        err.graphQLErrors?.forEach((error) => {
          // status code 409 is reserved for exclusivity conflict
          if (error.extensions.code === 409) {
            showNotification({
              notification: 'exclusivityDownloadError',
              customCopy: t(error.message),
              lifetime: 6000,
            });
          } else {
            openModal({
              content: ContactModal,
              contentProps: {
                recipient: ZAPIER_RECIPIENTS_MARKET,
                formVersion: 'support',
                title: 'directDownload.error.title',
                serverMessage: error.message,
                statusCode: error.extensions.code,
              },
            });
          }
        });
    });
  };

  const enrichedConversionEventOption = () =>
    enrichConversionEventOption(conversionEventOption, asset);

  const ButtonContent = ({ fullWidth, isPending }) => {
    if (!fullWidth) {
      if (isPending) {
        return <StyledSpinner inline />;
      } else {
        return <Redownload />;
      }
    } else {
      return (
        <Text bold>{t('directDownload.downloadButton.reDownload.label')}</Text>
      );
    }
  };

  return (
    <ThemeProvider>
      <StyledButton
        disabled={isPending}
        loading={isPending && fullWidth}
        onClick={actuallyDownload}
        customProps={{
          'data-test-id': TEST_DOWNLOAD_BUTTON,
        }}
        fullWidth={fullWidth}>
        <ButtonContent fullWidth={fullWidth} isPending={isPending} />
      </StyledButton>
    </ThemeProvider>
  );
};

export default withRouter(RedownloadButton);
