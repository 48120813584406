/* @flow */
import { connect } from 'react-redux';

import BlacklistCheck from './blacklistCheck.jsx';

import { allowNsfwPhoto } from '../../../helpers/actionCreators/redux';

import {
  getDevice,
  getShowNSFWContent,
  getIsNsfwPhotoOnWhitelist,
} from '../../../reducers/';

const mapStateToProps = (state, ownProps) => ({
  deviceType: getDevice(state).type,
  showNSFWContent: getShowNSFWContent(state),
  isNsfwPhotoOnWhitelist: getIsNsfwPhotoOnWhitelist(state, ownProps.photoId),
});

export default connect(mapStateToProps, { allowNsfwPhoto })(BlacklistCheck);
