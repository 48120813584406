/* @flow */
import { Icon } from 'eyeem-components';

import LegacyModalWrapper from './legacyModalWrapper';
import SignupForm from '../general/signupForm/';

import { TEST_MODAL_CLOSE } from '../../constants/pageObjectSelectors';

function SignupModal(props: {
  close: CloseModal,
  className?: string,
  eventPosition: string,
  signupHeadline: string,
  authRef?: string,
}) {
  return (
    <LegacyModalWrapper className={props.className}>
      <div className="modal_content">
        <button
          type="button"
          data-test-id={TEST_MODAL_CLOSE}
          className="dont-be-a-button modal_close"
          onClick={props.close}>
          <Icon size={16} type="close" />
        </button>
        <SignupForm
          isInModal
          callback={props.close}
          eventPosition={props.eventPosition}
          headline={props.signupHeadline}
          authRef={props.authRef}
          className="typo-topMarginBoost-small text-left"
        />
      </div>
    </LegacyModalWrapper>
  );
}

export default SignupModal;
