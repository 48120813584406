/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withApollo } from '@apollo/client/react/hoc';

import AddToLightbox from './addToLightbox.jsx';
import withRouter from '../../../helpers/hoc/withRouter';

import {
  getIsLightboxStripOpen,
  getSelectedLightboxId,
  getTempField,
  getIsAuthenticated,
} from '../../../reducers/';

import {
  clearFlyout,
  toggleLightboxStripThunk,
  openModal,
  addToLightboxUIAction,
  removeFromLightboxUIAction,
  setSessionFieldThunk,
} from '../../../helpers/actionCreators/redux';

import {
  TEMP_FIELD_CONVERSION_EVENT_OPTION,
  SESSION_FIELD_SELECTED_LIGHTBOX_ID,
} from '../../../constants/misc';

const mapStateToProps = (
  state: RootReducerState,
  ownProps: { asset: EyeEmPhoto }
) => {
  const contextLightboxId = getSelectedLightboxId(state);
  const asset = ownProps.asset;
  const isPhotoInContextLightbox = asset?.lightboxIds?.some(
    (lightboxId) => lightboxId === contextLightboxId
  );

  return {
    conversionEventOption: getTempField(
      state,
      TEMP_FIELD_CONVERSION_EVENT_OPTION
    ),
    isLightboxStripOpen: getIsLightboxStripOpen(state),
    isPhotoInContextLightbox,
    contextLightboxId,
    asset,
    isAuthenticated: getIsAuthenticated(state),
    shouldLoginOnAddToLightbox: true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearFlyout: (value) => dispatch(clearFlyout(value)),
  openModal: (value) => dispatch(openModal(value)),
  addToLightboxUIAction: (value) => dispatch(addToLightboxUIAction(value)),
  removeFromLightboxUIAction: (value) =>
    dispatch(removeFromLightboxUIAction(value)),
  toggleLightboxStrip: toggleLightboxStripThunk,
  selectLightbox: (value) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SELECTED_LIGHTBOX_ID,
        value,
      })
    ),
});

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddToLightbox);
