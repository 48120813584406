// @flow

import { Component } from 'react';
import t from 'counterpart';
import { Close } from '@eyeem-ui/icons';
import { Box } from '@eyeem-ui/atoms';
import { TEST_MODAL_CLOSE } from '../../../constants/pageObjectSelectors';
import { track } from '../../../helpers/tools';
import AuthForm from '../../checkout/authForm';
import LegacyModalWrapper from '../legacyModalWrapper';

type Props = {
  className: string,
  login: Function,
  signup: Function,
  close: CloseModal,
  isAuthPending: boolean,
  postAuthCallback: Function,
  setAuthRef: Function,
};
type State = {
  formType: string,
};

class SignupBuyerModal extends Component<Props, State> {
  state = {
    formType: 'signup',
  };

  componentDidMount() {
    this.props.setAuthRef({
      authRef: window.location.pathname,
    });
    track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.show'),
      eventName: t('tracking.eventName.ecomModalShow'),
      eventLabel: '',
      eventPosition: t('tracking.eventPosition.modalContent'),
    });
  }

  setForm = (value: string) => {
    this.setState({ formType: value });
  };

  render() {
    return (
      <LegacyModalWrapper
        className={this.props.className}
        formType={this.state.formType}
        width={[1, '496px']}>
        <div className="modal_content text-left">
          <button
            type="button"
            data-test-id={TEST_MODAL_CLOSE}
            className="dont-be-a-button modal_close"
            onClick={this.props.close}>
            <Close />
          </button>
          <Box>
            <AuthForm
              buyer
              isAuthPending={this.props.isAuthPending}
              login={this.props.login}
              signup={this.props.signup}
              close={this.props.close}
              setType={this.setForm}
              isInModal
              postAuthCallback={this.props.postAuthCallback}
            />
          </Box>
        </div>
      </LegacyModalWrapper>
    );
  }
}

export default SignupBuyerModal;
