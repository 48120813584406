// @flow
import { useState, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import { track, deprecatedCreateFileDownload } from '../../helpers/tools';
import { ASSET_TYPE_PHOTO } from '../../constants/assets';
import { GET_PHOTO } from '../../graphql/queries/photo';
import { GET_ILLUSTRATION } from '../../graphql/queries/illustration';
import { GET_LICENSING_INFO_FOR_ASSET } from '../../graphql/queries/license';
import { LICENSE_ASSET_THEN_REQUEST_DIRECT_DOWNLOAD } from '../../graphql/mutations/enterprise';

type Payload = {
  asset: EyeEmAsset,
  selectedLicense: { id: number },
  trackingEvent: FlatTrackingData,
  downloadRequirements?: DownloadRequirement[],
  isRedownload?: boolean,
};

const useEnterpriseDownload = () => {
  const [isPending, setIsPending] = useState(false);
  const client = useApolloClient();

  const startDownload = useCallback(
    ({
      asset,
      selectedLicense,
      trackingEvent,
      downloadRequirements,
      isRedownload = false,
    }: Payload) => {
      setIsPending(true);

      return client
        .mutate({
          mutation: LICENSE_ASSET_THEN_REQUEST_DIRECT_DOWNLOAD,
          variables: {
            assetId: asset.id,
            assetType: asset.type,
            isRedownload,
            requestParams: {
              licenceId: selectedLicense.id,
              requirements: downloadRequirements,
            },
          },
        })
        .then(({ data }) => {
          const url = data.licenseAssetThenRequestDirectDownload?.url;

          track(trackingEvent);

          if (url && url.length) {
            deprecatedCreateFileDownload(url);
          }
        })
        .catch((err) => {
          console.error('ERROR trying to download: ', err);
          // return error so we can catch it the initiating function
          // needed because this is sometimes called from a modal
          // closeModal() should only get triggered on successful download so that we can display contactModal on fail
          throw err;
        })
        .finally(() => {
          // Refetch licensing info to update counters
          setTimeout(() => {
            // setting timeout to prevent 'NetworkError when attempting to fetch resource' error on firefox.
            client.query({
              query: GET_LICENSING_INFO_FOR_ASSET,
              fetchPolicy: 'network-only',
              variables: {
                assetId: asset.id,
                assetType: asset.type,
              },
            });
            // Refetch photo or illustration from graphql
            if (asset.type === ASSET_TYPE_PHOTO) {
              client.query({
                query: GET_PHOTO,
                fetchPolicy: 'network-only',
                variables: {
                  photoId: asset.id,
                  isPhotographer: false,
                },
              });
            } else {
              client.query({
                query: GET_ILLUSTRATION,
                fetchPolicy: 'network-only',
                variables: {
                  illustrationId: asset.id,
                  isPhotographer: false,
                },
              });
            }
          }, 100);
          setIsPending(false);
        });
    },
    [client]
  );

  return { startDownload, isPending };
};

export default useEnterpriseDownload;
