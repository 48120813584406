// @flow
import { connect } from 'react-redux';

import SignupBuyerModal from './signupBuyerModal.jsx';

import { getIsAuthPending } from '../../../reducers';

import {
  loginThunk,
  signupThunk,
  setAuthRef,
} from '../../../helpers/actionCreators/redux';

const mapStateToProps = (state: RootReducerState) => {
  return {
    isAuthPending: getIsAuthPending(state),
  };
};

export default connect(mapStateToProps, {
  login: loginThunk,
  signup: signupThunk,
  setAuthRef,
})(SignupBuyerModal);
