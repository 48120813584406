import { connect } from 'react-redux';

import { openModal, setAuthRef } from '../../../helpers/actionCreators/redux';

import { getAuthUserNickname, getIsAuthenticated } from '../../../reducers/';

import UserFollowBtn from './userFollowBtn.jsx';

const mapStateToProps = (state) => ({
  authUserNickname: getAuthUserNickname(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = {
  openModal,
  setAuthRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFollowBtn);
