import { gql } from '@apollo/client';
import { SimpleUserFragment } from '../fragments/user';

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($password: String) {
    deleteAccount(password: $password)
  }
`;

export const FOLLOW_USER_TOGGLE = gql`
  mutation followUserToggle($nickname: String!, $shouldFollow: Boolean!) {
    followUserToggle(nickname: $nickname, shouldFollow: $shouldFollow) {
      ...SimpleUser
      follower
      following
    }
  }
  ${SimpleUserFragment}
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation updateAccount(
    $nickname: String
    $fullname: String
    $description: String
    $password: String
    $homepageUrl: String
    $email: String
    $profileImage: String
  ) {
    updateAccount(
      nickname: $nickname
      fullname: $fullname
      description: $description
      password: $password
      email: $email
      homepage: $homepageUrl
      profileImage: $profileImage
    ) {
      ...SimpleUser
      description
      email
      homepageUrl
    }
  }
  ${SimpleUserFragment}
`;

export const UPDATE_COVER_PHOTO = gql`
  mutation updateCoverPhoto($coverPhotoId: String) {
    updateCoverPhoto(coverPhotoId: $coverPhotoId) {
      ...SimpleUser
    }
  }
  ${SimpleUserFragment}
`;

export const SET_USER_FLAGS = gql`
  mutation setUserFlags($name: String!, $value: Boolean!) {
    setUserFlags(value: $value, name: $name) {
      email_confirmed
      email_community_updates
      email_photo_like
      email_photo_comment
      email_user_follower
      email_user_joined
      email_photo_comment_mention
      email_market_model_release_completed
      email_market_property_release_completed
      account_type
      account_market_seller
    }
  }
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation updateProfileImage($payload: UpdateProfileImageInput) {
    updateProfileImage(payload: $payload) {
      ...SimpleUser
    }
  }
  ${SimpleUserFragment}
`;
