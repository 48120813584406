import { connect } from 'react-redux';

import {
  getIsPhotoInCart,
  getTempField,
  getIsAuthenticated,
} from '../../../reducers/';

import {
  addOrUpdateCartItemThunk,
  clearFlyout,
} from '../../../helpers/actionCreators/redux';

import { TEMP_FIELD_CONVERSION_EVENT_OPTION } from '../../../constants/misc';

import AddToCartButton from './addToCartButton';

const mapStateToProps = (state, ownProps) => ({
  conversionEventOption: getTempField(
    state,
    TEMP_FIELD_CONVERSION_EVENT_OPTION
  ),
  isPhotoInCart: getIsPhotoInCart(state, ownProps.photo.id),
  isAuthenticated: getIsAuthenticated(state),
});

export default connect(mapStateToProps, {
  addOrUpdateCartItem: addOrUpdateCartItemThunk,
  clearFlyout,
})(AddToCartButton);
