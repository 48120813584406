/* @flow */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from 'theme-ui'; // eslint-disable-line
import { abbreviate, track } from '../../../helpers/tools';
import SignupModal from '../../modals/signupModal';
import { Button } from '@eyeem-ui/atoms';
import { Heart } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import {
  TEST_LIKE_BUTTON,
  TEST_LIKE_BUTTON_DISABLED,
} from '../../../constants/pageObjectSelectors';

import usePrevious from '../../../helpers/hooks/usePrevious';

type Props = {
  photo: EyeEmPhoto,
  like: Function,
  unLike: Function,
  setAuthRef?: Function,
  openModal: OpenModal,
  eventPosition: string,
  gridIndex: number,
  additionalClass?: string,
  isAuthenticated: boolean,
  toggleLikeRedux: Function,
  onPhotoView: boolean,
  likeResult: {
    loading: boolean,
  },
  unLikeResult: {
    loading: boolean,
  },
};

const LikeBtn = (props: Props) => {
  const [updatedLikes, setUpdatedLikes] = useState(props.photo);
  const [isPending, setIsPending] = useState(false);
  const prevId = usePrevious(props.photo.id);
  const { photo } = props;

  useEffect(() => {
    if (!props.onPhotoView && prevId !== photo.id) {
      setUpdatedLikes(photo);
    }
    if (props.onPhotoView) {
      setUpdatedLikes(photo);
    }
  }, [photo, prevId]);

  useEffect(() => {
    if (props.likeResult.loading || props.unLikeResult.loading) {
      setIsPending(true);
    } else {
      setIsPending(false);
    }
  }, [props.likeResult.loading, props.unLikeResult.loading]);

  const optimisticUpdate = () => {
    const { totalLikes = 0, liked } = updatedLikes;
    const getTotalLikes = () => {
      if (totalLikes >= 0) {
        if (liked) {
          return totalLikes - 1;
        }
        return totalLikes + 1;
      }
    };

    setUpdatedLikes({
      ...updatedLikes,
      liked: !liked,
      totalLikes: getTotalLikes(),
    });
  };

  const onClick = () =>
    !props.isAuthenticated ? setAuthRef() && signUpModal() : toggleLike();

  const setAuthRef = () =>
    props.setAuthRef({
      authRef: window.location.pathname,
    });

  const signUpModal = () =>
    props.openModal({
      contentProps: {
        signupHeadline: 'modal.signUp.like.headline',
        eventPosition: props.eventPosition,
        callback: () => toggleLike(),
      },
      content: SignupModal,
    });

  const toggleLike = () => {
    if (isPending) return;

    optimisticUpdate();

    const { liked } = props.photo;
    track({
      eventType: 'click_inbound',
      eventName: `interact_${liked ? 'unlike' : 'like'}`,
      eventAction: 'click_icon',
      eventLabel: liked ? 'unlike' : 'like',
      eventPosition: props.eventPosition,
      eventPositionIndex: props.gridIndex,
    });

    props.toggleLikeRedux({
      photoId: props.photo.id,
      liked,
      authenticated: props.isAuthenticated,
    });

    return liked ? props.unLike() : props.like();
  };

  if (!props.photo) {
    return null;
  }

  const merged = updatedLikes || props.photo;

  return (
    <ThemeProvider>
      <Button
        variant="subtle"
        icon={Heart}
        dataTestId={merged.liked ? TEST_LIKE_BUTTON : TEST_LIKE_BUTTON_DISABLED}
        sx={{
          path: {
            fill: merged.liked ? 'red50' : 'gray50',
            stroke: merged.liked ? 'red50' : 'gray100',
          },
        }}
        onClick={onClick}>
        {merged?.totalLikes > 0 ? (
          <span title={merged.totalLikes}>
            {abbreviate(parseInt(merged.totalLikes, 10))}
          </span>
        ) : null}
      </Button>
    </ThemeProvider>
  );
};

export default LikeBtn;
