import styled from '@emotion/styled';
import { Box } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

const Wrapper = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey20};
  color: ${(props) => props.theme.colors.textPrimary};
  max-width: unset;
  text-align: left;
`;

const LegacyModalWrapper = ({ children, className, ...rest }) => {
  return (
    <ThemeProvider>
      <Wrapper className={className} width={[1, '496px']} {...rest}>
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};

export default LegacyModalWrapper;
