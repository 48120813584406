/** @flow */
import { Button } from '@eyeem-ui/atoms';
import { Comment } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import { TEST_COMMENT_BUTTON } from '../../../constants/pageObjectSelectors';
import { abbreviate } from '../../../helpers/tools';

import Link from '../link/';

function CommentBtn(props: {
  // click callback
  onClick: Function,

  // Is the button part of a photo grid
  // (in that case it needs to link to the
  // actual photo view first)
  grid?: boolean,
  photo: { totalComments: number } & EyeEmPhoto,
  additionalClass?: string,
}) {
  const { photo } = props;
  return (
    <ThemeProvider>
      <Link href={props.grid ? `/p/${photo.id}` : ''}>
        <Button
          variant="subtle"
          icon={Comment}
          dataTestId={TEST_COMMENT_BUTTON}
          onClick={props.onClick}>
          {photo.totalComments > 0 && (
            <span title={photo.totalComments}>
              {abbreviate(parseInt(photo.totalComments, 10))}
            </span>
          )}
        </Button>
      </Link>
    </ThemeProvider>
  );
}

export default CommentBtn;
