import ConfirmPackDownloadModal from './confirmPackDownloadModal';
import { connect } from 'react-redux';

import { getTempField, getIsEnterpriseCustomer } from '../../../reducers';
import { closeModal, openModal } from '../../../helpers/actionCreators/redux';

import { TEMP_FIELD_CONVERSION_EVENT_OPTION } from '../../../constants/misc';

const mapStateToProps = (state) => ({
  conversionEventOption: getTempField(
    state,
    TEMP_FIELD_CONVERSION_EVENT_OPTION
  ),
  isEnterprise: getIsEnterpriseCustomer(state),
});

export default connect(mapStateToProps, {
  closeModal,
  openModal,
})(ConfirmPackDownloadModal);
