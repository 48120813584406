// @flow
import { connect } from 'react-redux';

import PackDownloadButtonCompact from './packDownloadButtonCompact.jsx';
import { openModal } from '../../../helpers/actionCreators/redux';

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(PackDownloadButtonCompact);
