// @flow
import { gql } from '@apollo/client';

import { SimpleIllustrationFragment } from '../fragments/illustration';
import { SimpleUserFragment } from '../fragments/user';

export const GET_ILLUSTRATIONS = gql`
  query getIllustrations($offset: Int, $limit: Int, $userId: String) {
    illustrations(offset: $offset, limit: $limit, userId: $userId) {
      total
      offset
      limit
      items {
        ...SimpleIllustration
        caption
        description: caption
        keywords
        createdAt
      }
    }
  }
  ${SimpleIllustrationFragment}
`;

export const GET_WEB_BACKEND_ILLUSTRATIONS = gql`
  query getWebBackendIllustrations($offset: Int, $limit: Int, $userId: String) {
    webBackendIllustrations(offset: $offset, limit: $limit, userId: $userId) {
      total
      offset
      limit
      items {
        ...SimpleIllustration
        lightboxIds
        caption
        description: caption
        keywords
        createdAt
      }
    }
  }
  ${SimpleIllustrationFragment}
`;

export const GET_ILLUSTRATION_REVIEW = gql`
  query getOwnIllustration($illustrationId: ID!) {
    ownIllustration(illustrationId: $illustrationId) {
      id
      review {
        status
        value
      }
    }
  }
`;

export const GET_ILLUSTRATION_STYLES_AND_CATEGORIES = gql`
  query getIllustrationStylesAndCategories($id: ID!) {
    ownIllustration(illustrationId: $id) {
      id
      styles {
        key
        value
      }
      categories {
        key
        value
      }
    }
  }
`;

export const GET_ILLUSTRATIONS_TOTAL = gql`
  query getIllustrationsTotal($userId: String) {
    illustrations(userId: $userId) {
      total
    }
  }
`;
export const GET_ILLUSTRATIONS_BY_ASSET_IDS = gql`
  query getIllustrationsByAssetIds($illustrationIds: [ID!]!) {
    illustrationsByAssetIds(illustrationIds: $illustrationIds) {
      ...SimpleIllustration
    }
  }
  ${SimpleIllustrationFragment}
`;

export const GET_ILLUSTRATION = gql`
  query getIllustration($illustrationId: ID!) {
    illustration(illustrationId: $illustrationId) {
      ...SimpleIllustration
      caption
      description: caption
      isLicensable
      lightboxIds
      tags
      updatedAt
      createdAt
      user {
        ...SimpleUser
        description
        follower
        following
      }
    }
  }
  ${SimpleIllustrationFragment}
  ${SimpleUserFragment}
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      key
      value
    }
  }
`;
export const GET_STYLES = gql`
  query getStyles {
    styles {
      key
      value
    }
  }
`;

export const GET_ILLUSTRATION_FOR_MISSIONS = gql`
  query getIllustrationForMissions($illustrationId: ID!) {
    illustration(illustrationId: $illustrationId) {
      ...SimpleIllustration
      relativeUrl
      updated
      updatedAt
      user {
        ...SimpleUser
        follower
        following
        description
      }
    }
  }
  ${SimpleIllustrationFragment}
  ${SimpleUserFragment}
`;
