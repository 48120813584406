/* @flow */
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  getIsBuyer,
  getIsEnterpriseCustomer,
  getAuthUser,
  getIsBCG,
} from '../../../../../../reducers/';

import ActionBar from './actionBar.jsx';

const mapStateToProps = (
  state: RootReducerState,
  ownProps: { asset: EyeEmAsset, user?: EyeEmUser }
) => ({
  authUser: getAuthUser(state),
  isBuyer: getIsBuyer(state),
  isBCG: getIsBCG(state),
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
  photographer: get(ownProps.asset, 'user'),
});

export default connect(mapStateToProps)(ActionBar);
