import { gql } from '@apollo/client';

export const ADD_RELEASE = gql`
  mutation addRelease($payload: AddReleasePayload) {
    addRelease(payload: $payload) {
      id
      photos {
        id
      }
    }
  }
`;

export const ADD_MULTI_RELEASE = gql`
  mutation addMultiRelease($payload: AddMultiReleasePayload) {
    addMultiRelease(payload: $payload) {
      id
      firstName
      lastName
      releaseType
    }
  }
`;

export const UPDATE_RELEASE = gql`
  mutation updateRelease($payload: UpdateReleasePayload) {
    updateRelease(payload: $payload) {
      id
      photos {
        id
      }
    }
  }
`;

export const UPDATE_RELEASE_WITH_PDF = gql`
  mutation updateReleaseWithPDF($payload: UpdateReleaseWithPDFPayload) {
    updateReleaseWithPDF(payload: $payload) {
      id
    }
  }
`;

export const REMOVE_RELEASE = gql`
  mutation removeRelease($payload: RemoveReleasePayload) {
    removeRelease(payload: $payload) {
      statusCode
    }
  }
`;

export const CANT_GET_RELEASE = gql`
  mutation cantGetRelease($payload: CantGetReleasePayload) {
    cantGetRelease(payload: $payload) {
      statusCode
    }
  }
`;
