/** @flow */
import { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import {
  openModal,
  setAuthRef,
  toggleLikeRedux,
} from '../../../helpers/actionCreators/redux';
import { getIsAuthenticated } from '../../../reducers/';
import { LIKE_PHOTO, UNLIKE_PHOTO } from '../../../graphql/mutations/photo';

import LikeBtn from './likeBtn.jsx';

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});

export default memo(
  compose(
    graphql(LIKE_PHOTO, {
      name: 'like',
      options: (ownProps) => ({
        variables: {
          photoId: ownProps.photo?.id || ownProps.photoId,
        },
      }),
    }),
    graphql(UNLIKE_PHOTO, {
      name: 'unLike',
      options: (ownProps) => ({
        variables: {
          photoId: ownProps.photo?.id || ownProps.photoId,
        },
      }),
    }),
    connect(mapStateToProps, {
      openModal,
      setAuthRef,
      toggleLikeRedux,
    })
  )(LikeBtn)
);
