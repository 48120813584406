/* @flow */
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getIsAdmin,
  getIsBuyer,
  getSelectedLightboxId,
  getIsPhotographer,
  getTempField,
  getIsPhotoInSelectedLightbox,
  getIsPhotoInCart,
} from '../../../../../reducers/';

import { storeConversionEventOptionThunk } from '../../../../../helpers/actionCreators/redux';

import withRouter from '../../../../../helpers/hoc/withRouter';

import { TEMP_FIELD_CONVERSION_EVENT_OPTION } from '../../../../../constants/misc';

import PhotoCard from './photoCard.jsx';

// copy of the one in redux to move on with graphql
const getAssetWasDownloaded = (asset: EyeEmAsset) =>
  Object.keys(get(asset, 'market.licensing.purchased') || {}).length > 0;

const mapStateToProps = (
  state: RootReducerState,
  ownProps: { photoId: EyeEmAssetId }
) => {
  const asset = ownProps.asset;
  const isBuyer = getIsBuyer(state);
  const selectedLightboxId = getSelectedLightboxId(state);
  const isPhotoInSelectedLightbox = asset?.lightboxIds
    ? asset?.lightboxIds?.some(
        (lightboxId) => lightboxId === selectedLightboxId
      )
    : getIsPhotoInSelectedLightbox(state, asset?.id);
  return {
    conversionEventOption: getTempField(
      state,
      TEMP_FIELD_CONVERSION_EVENT_OPTION
    ),
    isAdmin: getIsAdmin(state),
    isBuyer,
    isPhotographer: getIsPhotographer(state),
    isPhotoInSelectedLightbox,
    asset,
    photoWasDownloaded: getAssetWasDownloaded(asset),
    isInCart: getIsPhotoInCart(state, asset?.id),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    storeConversionEventOption: storeConversionEventOptionThunk,
  })
)(PhotoCard);
