// @flow
import RedownloadButton from './redownloadButton';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { TEMP_FIELD_CONVERSION_EVENT_OPTION } from '../../../constants/misc';

import { getTempField } from '../../../reducers';
import {
  showNotificationAndDismiss,
  openModal,
} from '../../../helpers/actionCreators/redux';

const mapStateToProps = (state) => {
  return {
    conversionEventOption: getTempField(
      state,
      TEMP_FIELD_CONVERSION_EVENT_OPTION
    ),
  };
};

export default compose(
  withApollo,
  connect(mapStateToProps, {
    showNotification: showNotificationAndDismiss,
    openModal,
  })
)(RedownloadButton);
